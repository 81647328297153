






import Logo from "@/components/Logo.vue";
import { computed, defineComponent } from "@vue/composition-api";
import { APP_BACKEND_URL } from "@/env_config";
import { IS_LOGGED_IN } from "@/store/auth";

export default defineComponent({
  name: "Login",
  components: { Logo },
  setup(props, { root }) {
    //route to home if we are already loggedin
    const isLoggedIn = computed(() => root.$store.getters[IS_LOGGED_IN]);
    isLoggedIn.value
      ? root.$router.push({ name: "Dashboard" })
      : (window.location.href = `${APP_BACKEND_URL}/api/login`);
  }
});
